<template>
    <b-container fluid class="py-4">
        <b-card>
            <b-row class="px-3 px-md-5 pt-3 pt-md-5">
                <b-col lg="6" class="px-3 px-md-5">
                    <router-view />
                </b-col>
                <b-col lg="6" class="px-3 px-md-5">
                    <!-- Reservation details is similar to order confirmation details but doesn't have a summary card -->
                </b-col>
            </b-row>
        </b-card>
    </b-container>
</template>
<script>
export default {
    name: 'ReserveDetailsIndex',
    components: {},
    props: {
        orderId: {
            type: [Number, String],
            default: () => null,
        },
    },
    async mounted() {
        try {
            if (!this.orderId || isNaN(this.orderId)) throw 'NotFound';
            await this.blockingRequest('orders/fetch', this.orderId);
        } catch (error) {
            this.$router.push({
                name: 'NotFound',
            });
        }
    },
};
</script>
<style lang="scss" scoped></style>
